import { useState, useCallback, useContext } from "react";

import { AuthContext } from "../../../shared/context/auth-context";
import { TutorContext } from "../context/tutor-context";

const Session = ({ sessionData }) => {
  const { startTime, _id, status, subjectId, studentId } = sessionData;
  const sessionForm = {
    year: new Date(startTime).getFullYear(),
    month: new Date(startTime).getMonth() + 1,
    day: new Date(startTime).getDate(),
    start: new Date(startTime).getHours(),
    courseId: "",
  };

  const auth = useContext(AuthContext);
  const tutorData = useContext(TutorContext);

  const applySessionHandler = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/user/sessions/",
        {
          method: "PUT",
          body: JSON.stringify({
            sessionId: _id,
            action: "apply",
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.accessToken,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        //updateTutorcontext
        const updatedSession = data.updatedSession;
        const index = tutorData.sessionList.findIndex(
          (session) => session._id === updatedSession._id
        );
        if (index !== -1) {
          const newSessionList = [...tutorData.sessionList];
          newSessionList[index] = { ...updatedSession };
          tutorData.updateTutorData({ sessionList: newSessionList });
        }
      } else if (data.status === 401) {
        console.log("verifying refresh token");
        auth.verifyRefreshToken();
      }
    } catch (err) {
      console.log(err);
    }
  }, [auth, _id, tutorData]);

  const sessionComponent = (
    <div>
      <div>{"Course subject: " + subjectId.title}</div>
      <div>startTime</div>
      <div>Year: {sessionForm.year}</div>
      <div>Month: {sessionForm.month}</div>
      <div>Day: {sessionForm.day}</div>
      <div>Start Time: {sessionForm.start}</div>
      <div>
        Status:{" "}
        {new Date() > new Date(startTime)
          ? "Start Time Passed"
          : studentId
          ? studentId === auth.userId
            ? "applied"
            : "closed"
          : status}
      </div>
      {status === "open" ? (
        <div>
          {/* <button onClick={() => setMode("edit")}>Edit</button> */}
          <button
            onClick={() => {
              applySessionHandler();
            }}
          >
            Select session
          </button>
        </div>
      ) : null}
    </div>
  );

  return <> {sessionComponent}</>;
};

const EditSession = ({ saveHandler, year, month, date, courseList }) => {
  const [sessionForm, setSessionForm] = useState({
    year: year,
    month: month,
    day: date,
    start: 9,
    courseId: "",
  });

  const sessionEditHandler = (event) => {
    setSessionForm({ ...sessionForm, [event.target.name]: event.target.value });
  };

  const sessionSaveHandler = () => {
    saveHandler(sessionForm);
    setSessionForm({});
  };

  const subjectSelectHandler = (e) => {
    setSessionForm({ ...sessionForm, courseId: e.target.value });
  };

  console.log(courseList);

  return (
    <div>
      Edit session
      <div>year</div>
      <input
        name="year"
        value={sessionForm.year}
        onChange={sessionEditHandler}
      />
      <div>month</div>
      <input
        name="month"
        value={sessionForm.month}
        onChange={sessionEditHandler}
      />
      <div>day</div>
      <input name="day" value={sessionForm.day} onChange={sessionEditHandler} />
      <div>Start time</div>
      <input
        name="start"
        value={sessionForm.start}
        onChange={sessionEditHandler}
      />
      <select onChange={subjectSelectHandler}>
        <option>Select a subject</option>
        {courseList.map((course) => (
          <option key={course._id} value={course._id}>
            {course.title}
          </option>
        ))}
      </select>
      <button onClick={sessionSaveHandler}>Save</button>
      <button onClick={saveHandler}>Cancel</button>
    </div>
  );
};

export { Session, EditSession };
