import { useNavigate } from "react-router-dom";

import classes from "./TutorCard.module.css";

const TutorCard = ({ data }) => {
  const { fullName, subjectList, _id } = data;
  const navigate = useNavigate();

  return (
    <div
      className={classes.Card}
      onClick={() => {
        navigate("/tutors/" + _id);
      }}
    >
      <h4>Name: {" " + fullName}</h4>
      {subjectList.length > 0 ? <h5>{subjectList[0].subject.title}</h5> : null}
    </div>
  );
};

export default TutorCard;
