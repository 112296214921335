import { useNavigate } from "react-router-dom";

import classes from "./CourseBox.module.css";
const CourseBox = ({ courseId, title, subtitle, tutor, level }) => {
  const navigate = useNavigate();

  const fetchCourseDetailHandler = () => {
    navigate("/courses/" + courseId);
  };
  return (
    <div className={classes.CourseBox} onClick={fetchCourseDetailHandler}>
      <h5>{title}</h5>
      <div>{subtitle}</div>
      <div>tutor: {tutor}</div>
      <div>level: {level}</div>
    </div>
  );
};

export default CourseBox;
