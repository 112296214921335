import { useState, useCallback, useContext } from "react";

import { AuthContext } from "../../../shared/context/auth-context";

const Session = ({
  startTime,
  id,
  status,
  saveHandler,
  year,
  month,
  date,
  course,
  mySession,
}) => {
  const sessionForm = {
    year: new Date(startTime).getFullYear(),
    month: new Date(startTime).getMonth() + 1,
    day: new Date(startTime).getDate(),
    start: new Date(startTime).getHours(),
    courseId: "",
  };
  const [currentStatus, setCurrentStatus] = useState(status);

  const auth = useContext(AuthContext);

  const applySessionHandler = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/user/apply-session",
        {
          method: "PUT",
          body: JSON.stringify({
            sessionId: id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.accessToken,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        //apply the change of current session
        setCurrentStatus("applied");
      } else if (data.status === 401) {
        console.log("verifying refresh token");
        auth.verifyRefreshToken();
      }
    } catch (err) {
      console.log(err);
    }
  }, [auth, id]);

  const sessionComponent = (
    <div>
      <div>{"Course subject: " + course.title}</div>
      <div>startTime</div>
      <div>Year: {sessionForm.year}</div>
      <div>Month: {sessionForm.month}</div>
      <div>Day: {sessionForm.day}</div>
      <div>Start Time: {sessionForm.start}</div>
      <div>
        Status:{" "}
        {new Date() > new Date(startTime)
          ? "Start Time Passed"
          : mySession
          ? "applied"
          : currentStatus}
      </div>
      {currentStatus === "open" ? (
        <div>
          {/* <button onClick={() => setMode("edit")}>Edit</button> */}
          <button
            onClick={() => {
              applySessionHandler();
            }}
          >
            Select session
          </button>
        </div>
      ) : null}
    </div>
  );

  return <> {sessionComponent}</>;
};

const EditSession = ({ saveHandler, year, month, date, courseList }) => {
  const [sessionForm, setSessionForm] = useState({
    year: year,
    month: month,
    day: date,
    start: 9,
    courseId: "",
  });

  const sessionEditHandler = (event) => {
    setSessionForm({ ...sessionForm, [event.target.name]: event.target.value });
  };

  const sessionSaveHandler = () => {
    saveHandler(sessionForm);
    setSessionForm({});
  };

  const subjectSelectHandler = (e) => {
    setSessionForm({ ...sessionForm, courseId: e.target.value });
  };

  console.log(courseList);

  return (
    <div>
      Edit session
      <div>year</div>
      <input
        name="year"
        value={sessionForm.year}
        onChange={sessionEditHandler}
      />
      <div>month</div>
      <input
        name="month"
        value={sessionForm.month}
        onChange={sessionEditHandler}
      />
      <div>day</div>
      <input name="day" value={sessionForm.day} onChange={sessionEditHandler} />
      <div>Start time</div>
      <input
        name="start"
        value={sessionForm.start}
        onChange={sessionEditHandler}
      />
      <select onChange={subjectSelectHandler}>
        <option>Select a subject</option>
        {courseList.map((course) => (
          <option key={course._id} value={course._id}>
            {course.title}
          </option>
        ))}
      </select>
      <button onClick={sessionSaveHandler}>Save</button>
      <button onClick={saveHandler}>Cancel</button>
    </div>
  );
};

export { Session, EditSession };
