import { useState, useEffect, useCallback, useContext } from "react";

import TutorCard from "../components/TutorCard";

// import TutorsList from "./DummyTutorData";
import { AuthContext } from "../../../shared/context/auth-context";

const fieldList = [
  "Art",
  "Science",
  "Math",
  "Foreign Language",
  "Others",
  "Social Science",
  "English",
  "Music",
];

const defaultFilter = {
  category: {
    ap: false,
    sat: false,
    act: false,
    ae: false,
  },

  field: "",
};

const createQueryString = (filter) => {
  let queryString = "";

  // Add searchterm if it exists
  // if (filter.searchTerm) {
  //   queryString += `searchterm=${filter.searchTerm}&`;
  // }

  // Add category if it exists and is an array
  let categoryList = [];
  Object.keys(filter.category).forEach((key) => {
    if (filter.category[key]) {
      categoryList.push(key);
    }
  });
  if (categoryList.length > 0) {
    queryString += `category=${categoryList.join(",")}&`;
  }

  if (filter.field) {
    queryString += `field=${filter.field}`;
  }

  // Remove the trailing '&' if exists and return the query string
  return queryString;
};

const Tutors = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState(defaultFilter);
  const [queryString, setQueryString] = useState("");
  const [tutors, setTutors] = useState([]);

  const auth = useContext(AuthContext);

  const inputChangeHandler = (event) => {
    if (event.target.name === "searchTerm") {
      setSearchTerm(event.target.value);
    } else if (event.target.name === "category") {
      const newCategory = {
        ...filter.category,
        [event.target.id]: event.target.checked,
      };
      setFilter((filter) => {
        const newFilter = {
          ...filter,
          category: newCategory,
        };
        const newQueryString = createQueryString(newFilter);
        setQueryString(newQueryString);
        return newFilter;
      });
    } else {
      setFilter(() => {
        const newFilter = {
          ...filter,
          [event.target.id]: event.target.value,
        };
        const newQueryString = createQueryString(newFilter);
        setQueryString(newQueryString);
        return newFilter;
      });
    }
  };

  const fetchTutors = useCallback(
    async (query) => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + "/user/tutors?" + query,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.accessToken,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch tutors");
        }
        const data = await response.json();
        setTutors(data.tutors);
        console.log(data.tutors);
      } catch (err) {
        console.log(err);
      }
    },
    [auth]
  );

  let tutorCards, numTutors;
  if (tutors.length > 0) {
    numTutors = Math.min(10, tutors.length);
    console.log(numTutors);
    tutorCards = tutors
      .slice(0, numTutors)
      .map((item) => <TutorCard key={item._id} data={item} />);
  }

  useEffect(() => {
    fetchTutors();
  }, [fetchTutors]);

  return (
    <div>
      <h3>{tutors ? tutors.length : "no tutors found"}</h3>
      <div>
        <label>Search Tutors </label>
        <input
          id="searchTerm"
          name="searchTerm"
          value={searchTerm}
          onChange={inputChangeHandler}
          placeholder="search tutors"
        />
        <button
          onClick={() => {
            setFilter(defaultFilter);
            setQueryString("");
            fetchTutors(`searchterm=${searchTerm}`);
          }}
        >
          Search tutors
        </button>
      </div>
      <div>
        <label>Category : </label>
        <label>
          AP
          <input
            name="category"
            id="ap"
            type="checkbox"
            checked={filter.category.ap}
            onChange={inputChangeHandler}
          />
        </label>
        <label>
          SAT
          <input
            name="category"
            id="sat"
            type="checkbox"
            checked={filter.category.sat}
            onChange={inputChangeHandler}
          />
        </label>
        <label>
          ACT
          <input
            name="category"
            id="act"
            type="checkbox"
            checked={filter.category.act}
            onChange={inputChangeHandler}
          />
        </label>
        <label>
          Academic Enrichment
          <input
            name="category"
            id="ae"
            type="checkbox"
            checked={filter.category.ae}
            onChange={inputChangeHandler}
          />
        </label>
      </div>
      <div>
        <select id="field" onChange={inputChangeHandler} value={filter.field}>
          <option value="">Select a field</option>
          {fieldList.map((field) => {
            return (
              <option value={field} key={field}>
                {field}
              </option>
            );
          })}
        </select>
      </div>
      <div>
        <button onClick={() => setFilter(defaultFilter)}>Clear filters</button>
        <button
          onClick={() => {
            setSearchTerm("");
            fetchTutors(queryString);
          }}
        >
          Search
        </button>
      </div>
      <div>{tutorCards}</div>
    </div>
  );
};

export default Tutors;
