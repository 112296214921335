import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../../shared/context/auth-context";
import { ProfileContext } from "../../../shared/context/profile-context";

import classes from "./Profile.module.css";

const Profile = () => {
  const [profileForm, setProfileForm] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });
  const profile = useContext(ProfileContext);

  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (profile) {
      setProfileForm(profile);
    } else {
      // redirect to login page if not authenticated
    }
  }, [profile]);

  const inputChangeHnadler = (e) => {
    setProfileForm({ ...profileForm, [e.target.name]: e.target.value });
  };

  const profileSubmitHandler = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/user/update-profile",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.accessToken,
          },
          body: JSON.stringify({ profileData: profileForm }),
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        // update user data in auth context
        navigate("/dashboard");
      } else {
        alert("Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile", error);
      alert("Error updating profile");
    }
  };

  return (
    <div>
      <h1>Profile Page</h1>
      <p>Welcome to your profile page!</p>
      <div className={classes.NameInputBox}>
        <div className={classes.InputBox}>
          <label className={classes.Label}>First Name</label>
          <input
            className={classes.Input}
            name="firstName"
            value={profileForm.firstName}
            onChange={inputChangeHnadler}
          />
        </div>
        <div className={classes.InputBox}>
          <label className={classes.Label}>Last Name</label>
          <input
            className={classes.Input}
            name="lastName"
            value={profileForm.lastName}
            onChange={inputChangeHnadler}
          />
        </div>
      </div>
      <button className={classes.SubmitButton} onClick={profileSubmitHandler}>
        Submit
      </button>
    </div>
  );
};

export default Profile;
