import { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../shared/context/auth-context";
import { TutorContext } from "../context/tutor-context";

import Calendar from "../components/Calendar";

const TutorDetail = () => {
  const [tutorData, setTutorData] = useState(null);
  const [, setisloading] = useState(true);

  const params = useParams();
  const tutorId = params.tutorId;
  const auth = useContext(AuthContext);

  const getTutorDetails = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/user/tutors/" + tutorId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.accessToken,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        setTutorData(data.tutorData);
        console.log("data: ", data.tutorData);
        setisloading(false);
      } else if (data.status === 401) {
        console.log("verifying refresh token");
        auth.verifyRefreshToken();
      }
    } catch (err) {
      console.log(err);
    }
  }, [auth, tutorId]);

  useEffect(() => {
    getTutorDetails();
  }, [getTutorDetails]);

  const updateTutorData = (data) => {
    setTutorData((prev) => {
      return { ...prev, ...data };
    });
  };
  console.log("td", tutorData);

  return (
    <TutorContext.Provider
      value={{
        ...tutorData,
        updateTutorData: updateTutorData,
      }}
    >
      {!tutorData ? (
        <div>There's no data for this tutor!</div>
      ) : (
        <div>
          <div>{tutorData.email}</div>
          {/* <div>{subjectItems}</div> */}
          {tutorData.subjectList && tutorData.subjectList.length > 0
            ? tutorData.subjectList.map((item) => {
                return (
                  <div key={item._id}>
                    <div>{item.subject.title}</div>
                    <div>{item.subject.field}</div>
                    <div>{item.subject.category}</div>
                    {item.subject.grade
                      ? item.subject.grade.map((g) => (
                          <div key={g}>grade: {g}</div>
                        ))
                      : null}
                  </div>
                );
              })
            : null}
          <Calendar />
        </div>
      )}
    </TutorContext.Provider>
  );
};

export default TutorDetail;
