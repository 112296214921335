import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { AuthContext } from "../../../shared/context/auth-context";

import CalendarView from "../components/Calendar";

const CourseDetail = () => {
  const [courseData, setCourseData] = useState(null);
  const [isLoading, setisloading] = useState(true);

  const navigate = useNavigate();
  const params = useParams();
  const courseId = params.courseId;
  const auth = useContext(AuthContext);

  const getCourseDetails = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/user/course-detail/" + courseId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.accessToken,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        setCourseData(data.courseData);
        setisloading(false);
      } else if (data.status === 401) {
        console.log("verifying refresh token");
        auth.verifyRefreshToken();
      }
    } catch (err) {
      console.log(err);
    }
  }, [auth, courseId]);

  useEffect(() => {
    getCourseDetails();
  }, [getCourseDetails]);

  return isLoading ? (
    <div>Loading...</div>
  ) : !courseData ? (
    <div>There's no data for this course!</div>
  ) : (
    <div>
      <div>{courseData.title}</div>
      <div>{courseData.subtitle}</div>
      <div>
        <div>tutor: {courseData.tutorId.email}</div>
        <button
          onClick={() => {
            navigate("/messages", {
              state: { tutorId: courseData.tutorId._id },
            });
          }}
        >
          Contact the tutor
        </button>
      </div>
      <div>
        subject:{" "}
        {courseData.subjectId.subjectType +
          " " +
          courseData.subjectId.subjectName}
      </div>
      <div>description: {courseData.description}</div>
      <div>level: {courseData.level}</div>
      <div>Sessions</div>
      <CalendarView courseId={courseData._id} />
    </div>
  );
};

export default CourseDetail;
