import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Session = ({ session }) => {
  const { startTime, status, subjectId, _id } = session;

  const navigate = useNavigate();

  const sessionForm = {
    year: new Date(startTime).getFullYear(),
    month: new Date(startTime).getMonth() + 1,
    day: new Date(startTime).getDate(),
    start: new Date(startTime).getHours(),
    courseId: "",
  };

  const sessionComponent = (
    <div>
      <div>{"Course subject: " + subjectId.title}</div>
      <div>startTime</div>
      <div>Year: {sessionForm.year}</div>
      <div>Month: {sessionForm.month}</div>
      <div>Day: {sessionForm.day}</div>
      <div>Start Time: {sessionForm.startTime}</div>
      <div>
        Status:{" "}
        {new Date() > new Date(startTime) ? "Start Time Passed" : status}
      </div>
      {status === "approved" ? (
        <div>
          <button
            onClick={() => {
              navigate("/learn/" + _id);
            }}
          >
            start session (if time has come)
          </button>
        </div>
      ) : null}
    </div>
  );

  return <> {sessionComponent}</>;
};

const EditSession = ({ saveHandler, year, month, date, courseList }) => {
  const [sessionForm, setSessionForm] = useState({
    year: year,
    month: month,
    day: date,
    start: 9,
    courseId: "",
  });

  const sessionEditHandler = (event) => {
    setSessionForm({ ...sessionForm, [event.target.name]: event.target.value });
  };

  const sessionSaveHandler = () => {
    saveHandler(sessionForm);
    setSessionForm({});
  };

  const subjectSelectHandler = (e) => {
    setSessionForm({ ...sessionForm, courseId: e.target.value });
  };

  return (
    <div>
      Edit session
      <div>year</div>
      <input
        name="year"
        value={sessionForm.year}
        onChange={sessionEditHandler}
      />
      <div>month</div>
      <input
        name="month"
        value={sessionForm.month}
        onChange={sessionEditHandler}
      />
      <div>day</div>
      <input name="day" value={sessionForm.day} onChange={sessionEditHandler} />
      <div>Start time</div>
      <input
        name="start"
        value={sessionForm.start}
        onChange={sessionEditHandler}
      />
      <select onChange={subjectSelectHandler}>
        <option>Select a subject</option>
        {courseList.map((course) => (
          <option key={course._id} value={course._id}>
            {course.title}
          </option>
        ))}
      </select>
      <button onClick={sessionSaveHandler}>Save</button>
      <button onClick={saveHandler}>Cancel</button>
    </div>
  );
};

export { Session, EditSession };
