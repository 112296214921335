import { useContext } from "react";

import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../../../shared/context/auth-context";
import { ProfileContext } from "../../../shared/context/profile-context";

import classes from "./Dashboard.module.css";

import { MdOutlinePersonSearch } from "react-icons/md";
import { FcCalendar } from "react-icons/fc";
import { MdOutlineRateReview } from "react-icons/md";
import { FaUniversity } from "react-icons/fa";
import { MdTimer } from "react-icons/md";

import emptyUserImage from "../../../shared/icons/user.png";

const Dashboard = () => {
  // const auth = useContext(AuthContext);
  const profile = useContext(ProfileContext);
  const profileData = profile.profileData;

  console.log(profile);

  const navigate = useNavigate();

  // const fetchDashboardData = useCallback(async () => {
  //   try {
  //     const response = await fetch(
  //       process.env.REACT_APP_BACKEND_URL + "/user/get-profile-data",
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + auth.accessToken,
  //         },
  //       }
  //     );
  //     const data = await response.json();

  //     if (data.status === 200) {
  //       profile.addProfileData(data.profileData);
  //     } else if (data.status === 401) {
  //       console.log("verifying refresh token");
  //       auth.verifyRefreshToken();
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, [auth,profile]);

  // useEffect(() => {
  //   fetchDashboardData();
  // }, [fetchDashboardData]);

  return (
    <div className={classes.Container}>
      <div className={classes.DashboardContents}>
        <div className={classes.LeftContents}>
          <div className={classes.UserOverview}>
            <div>
              {profileData && profileData.imageURL ? (
                <img
                  className={classes.UserIcon}
                  src={profileData.imageURL}
                  alt="profile_Image"
                />
              ) : (
                <img
                  className={classes.UserIcon}
                  src={emptyUserImage}
                  alt="profile_Image"
                />
              )}
            </div>

            {profileData && profileData.firstName && profileData.lastName ? (
              <h3>
                Welcome to Gogos Edu{" "}
                {profileData.firstName + " " + profileData.lastName}
              </h3>
            ) : (
              <>
                <h3>Welcome to A-List!</h3>
                <h4
                  className={classes.ProfileLink}
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  {" "}
                  First comlete your profile!
                </h4>
              </>
            )}

            <div className={classes.UserProgress}>
              <div className={classes.StatBox}>
                <div>Lessons</div>
                <div className={classes.Stat}>0</div>
              </div>
              <div className={classes.StatBox}>
                <div>Minutes with tutors</div>
                <div className={classes.Stat}>0</div>
              </div>
            </div>
          </div>
          <div className={classes.Credits}>
            <div className={classes.CreditsBox}>
              <div className={classes.StatBox}>
                <div>Credits</div>
                <div className={classes.Stat}>0</div>
              </div>
              <button className={classes.ButtonMoreCredit}>
                Buy more credits!
              </button>
            </div>
          </div>
        </div>
        <div className={classes.RightContents}>
          <div className={classes.GridBox}>
            <div className={classes.UserItem}>
              <div className={classes.ItemBox}>
                <MdOutlinePersonSearch className={classes.Icon} />
                <div className={classes.ItemTextBox}>Browse Tutors</div>
              </div>
            </div>
            <div
              className={classes.UserItem}
              onClick={() => {
                navigate("/schedule");
              }}
            >
              <div className={classes.ItemBox}>
                <FcCalendar className={classes.Icon} />
                <div className={classes.ItemTextBox}>Calendar</div>
              </div>
            </div>
            <div className={classes.UserItem}>
              <div className={classes.ItemBox}>
                <MdOutlineRateReview className={classes.Icon} />
                <div className={classes.ItemTextBox}>
                  Review your previous classes
                </div>
              </div>
            </div>
            <div className={classes.UserItem}>
              <div className={classes.ItemBox}>
                <FaUniversity className={classes.Icon} />
                <div className={classes.ItemTextBox}>
                  College app service coming soon
                </div>
              </div>
            </div>
          </div>
          <div className={classes.Upcoming}>
            <div className={classes.UpcomingBox}>
              <MdTimer size="50px" />
              Upcoming Classes
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
