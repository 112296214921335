import { useState, useEffect, useCallback, useContext } from "react";

import { AuthContext } from "../../../shared/context/auth-context";

import classes from "./Courses.module.css";

import CourseBox from "../components/CourseBox";

import subjectList from "../components/subjectList";

const Courses = () => {
  const [courseList, setCourseList] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const auth = useContext(AuthContext);

  const searchInputChangeHandler = (e) => {
    setSearchInput(e.target.value);
  };

  const fetchCourses = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/user/get-courses",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.accessToken,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        setCourseList(data.courseList);
      } else if (data.status === 401) {
        console.log("verifying refresh token");
        auth.verifyRefreshToken();
      }
    } catch (err) {
      console.log(err);
    }
  }, [auth]);

  const searchCourseHandler = async () => {
    //searach query
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL +
          "/user/search-courses/" +
          searchInput,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.accessToken,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        console.log(data);
        setCourseList(data.courseList);
      } else if (data.status === 401) {
        console.log("verifying refresh token");
        auth.verifyRefreshToken();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const querySubmitHandler = async (event) => {
    event.preventDefault();
    searchCourseHandler(searchInput);
  };

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  return (
    <div className={classes.Container}>
      <div className={classes.SubjectsTab}>
        <div className={classes.SubjectTypeBox}>
          <h4>AP</h4>
          {subjectList["AP"].map((subject) => {
            return (
              <div className={classes.Subject} key={"AP" + subject}>
                {subject}
              </div>
            );
          })}
        </div>
        <div className={classes.SubjectTypeBox}>
          <h4>SAT</h4>
          {subjectList["SAT"].map((subject) => {
            return (
              <div className={classes.Subject} key={subject}>
                {subject}
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.MainContainer}>
        <form onSubmit={querySubmitHandler}>
          <div className={classes.SearchLine}>
            <input
              className={classes.SearchBar}
              value={searchInput}
              onChange={searchInputChangeHandler}
              placeholder="Search Courses"
            />
            <div className={classes.SearchButton}>
              <button>Search</button>
            </div>
          </div>
        </form>
        <div className={classes.CourseContainer}>
          {courseList && courseList.length > 0 ? (
            courseList.map((course) => {
              return (
                <CourseBox
                  key={course._id}
                  courseId={course._id}
                  title={course.title}
                  subtitle={course.subtitle}
                  tutor={course.tutorId.email}
                  level={course.level}
                />
              );
            })
          ) : (
            <div>No courses</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Courses;
