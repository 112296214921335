import { Route, Navigate, Routes } from "react-router-dom";

import Navigation from "./Pages/Navigation/Navigation";
import ScrollToTop from "./Pages/Navigation/ScrollToTop";
import Footer from "./Pages/Navigation/Footer";

import Home from "./Pages/Home/containers/Home";
import Dashboard from "./Pages/User/containers/Dashboard";
import Tutors from "./Pages/Tutors/containers/Tutors";
import TutorDetail from "./Pages/Tutors/containers/TutorDetail";
import Login from "./Pages/Authentication/containers/Login";
import Signup from "./Pages/Authentication/containers/Signup";
import CourseDetail from "./Pages/Courses/containers/CourseDetail";
import Courses from "./Pages/Courses/containers/Courses";
import Settings from "./Pages/Settings/containers/Settings";
import Chatting from "./Pages/Chatting/containers/Chatting";
import Schedule from "./Pages/Schedule/containers/Schedule";
import Profile from "./Pages/Profile/containers/Profile";
import Zoom from "./Pages/Zoom/containers/Zoom";
import Learn from "./Pages/Learn/containers/Learn";

import Test from "./Pages/Test/containers/Test";

const StudentRoutes = ({ isSignedIn }) => {
  let routes;
  if (isSignedIn) {
    routes = (
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/tutors/:tutorId" element={<TutorDetail />} />
        <Route exact path="/tutors" element={<Tutors />} />
        <Route exact path="/courses/:courseId" element={<CourseDetail />} />
        <Route exact path="/courses" element={<Courses />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/account-settings" element={<Settings />} />
        <Route exact path="/messages" element={<Chatting />} />
        <Route exact path="/schedule" element={<Schedule />} />
        <Route exact path="/zoom/:sessionId" element={<Zoom />} />
        <Route exact path="/Learn/:sessionId" element={<Learn />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/test" element={<Test />} />
        <Route path="/*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/tutors/:tutorId" element={<TutorDetail />} />
        <Route exact path="/tutors" element={<Tutors />} />
        <Route exact path="/courses/:courseId" element={<CourseDetail />} />
        <Route exact path="/courses" element={<Courses />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route path="/*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }
  return (
    <>
      <ScrollToTop />
      <Navigation />
      <main>{routes}</main>
      <Footer />
    </>
  );
};

export default StudentRoutes;
