import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../../shared/context/auth-context";
import { ProfileContext } from "../../../shared/context/profile-context";

import CustomGoogleLogin from "../components/GoogleLogin";

import Input from "../../../shared/components/FormElements/Input";

import classes from "./Auth.module.css";
import Logo from "../../../shared/icons/A-List_logo_rmbg.svg";
// import GoogleLogo from "../../shared/icons/google-logo.png";
import FacebookLogo from "../../../shared/icons/facebook-logo.png";
import AppleLogo from "../../../shared/icons/apple-logo.png";

const Login = () => {
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");

  const auth = useContext(AuthContext);
  const profile = useContext(ProfileContext);
  const navigate = useNavigate();

  const inputChangeHandler = (event) => {
    if (event.target.name === "email") {
      setEmailInput(event.target.value);
    } else if (event.target.name === "password") {
      setPasswordInput(event.target.value);
    }
  };

  const loginRequestHandler = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/user/login",
        {
          method: "POST",
          body: JSON.stringify({ email: emailInput, password: passwordInput }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      const authData = data.authData;
      const profileData = data.profileData;
      console.log(profileData);
      if (data.status === 200) {
        auth.login(authData);
        profile.setProfileData(profileData);
        //
        // profile.addProfileData(profileData);
        //

        // auth.login(
        //   data.userId,
        //   data.email,
        //   data.accessToken,
        //   data.refreshToken,
        //   new Date(new Date().getTime() + 1000 * login_duration),
        //   data.firstName,
        //   data.lastName,
        //   data.profileImage
        // );
        navigate("/dashboard");
      } else if (data.status === 403) {
        setPasswordInput("");
        alert(data.message);
      }
    } catch (err) {}
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    loginRequestHandler();
  };

  return (
    <div className={classes.Container}>
      <div className={classes.LoginBox}>
        <div className={classes.Title}>
          <img src={Logo} alt="/" className={classes.Logo} />
          <h3 style={{ marginBottom: 0 }}>Student Sign in</h3>
        </div>
        <form className={classes.FormBox} onSubmit={onSubmitHandler}>
          <Input
            title="Email"
            name="email"
            value={emailInput}
            onChange={inputChangeHandler}
            type="email"
          />
          <Input
            title="Password"
            name="password"
            value={passwordInput}
            onChange={inputChangeHandler}
            type="password"
          />
          <button className={classes.Button} onClick={null}>
            Log In
          </button>
          <hr />
        </form>
        <div className={classes.SocialLoginBox}>
          <div className={classes.SocialLoginItem}>
            {/* <img className={classes.SocialLogo} src={GoogleLogo} alt="/" />
            Login with Google */}
            <CustomGoogleLogin />
          </div>
          <div className={classes.SocialLoginItem}>
            <img className={classes.SocialLogo} src={FacebookLogo} alt="/" />
            Login with Facebook
          </div>
          <div className={classes.SocialLoginItem}>
            <img className={classes.SocialLogo} src={AppleLogo} alt="/" />
            Login with Apple
          </div>
        </div>

        <div className={classes.RedirectLine}>
          <p className={classes.RedirectMessage}>New to A-List?</p>
          <button
            className={classes.RedirectLink}
            onClick={() => {
              navigate("/signup");
            }}
          >
            Sign up here
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
