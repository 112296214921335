import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker.getRegistrations().then((registrations) => {
//     registrations.forEach((registration) => {
//       registration.unregister();
//     });
//   });
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
