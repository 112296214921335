import React, { useState, useEffect, useContext } from "react";

import { TutorContext } from "../context/tutor-context";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import classes from "./Calendar.module.css";
import { Session } from "./Session.js";

const CalendarView = () => {
  const [value, onChange] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [currentDaySessions, setCurrentDaySessions] = useState([]);
  // const [sessionList, setSessionList] = useState([]);

  const tutorData = useContext(TutorContext);
  const sessionList = tutorData.sessionList;

  // useEffect(() => {
  //   fetchSessions();
  //   setIsLoadingSessions(false);
  // }, [fetchSessions]);
  useEffect(() => {
    if (sessionList && sessionList.length > 0) {
      setCurrentDaySessions(
        sessionList.filter((x) => {
          const offset = Math.floor(
            (new Date(x.startTime) - value) / (1000 * 60)
          );
          if (offset >= 0 && offset < 60 * 24) {
            return true;
          } else {
            return false;
          }
        })
      );
    }
  }, [value, sessionList]);

  return (
    <div>
      <Calendar
        onChange={onChange}
        value={value}
        tileContent={({ date, view }) => {
          if (
            sessionList &&
            sessionList.length > 0 &&
            sessionList.find(
              (x) =>
                moment(new Date(x.startTime.split("T")[0])).format(
                  "YYYY-MM-DD"
                ) === moment(date).format("YYYY-MM-DD")
            )
          ) {
            return (
              <>
                <div className="flex justify-center items-center absoluteDiv">
                  <div className={classes.Dot}></div>
                </div>
              </>
            );
          }
        }}
      />
      <div>Selected date: {value.toString()}</div>
      <div>
        {currentDaySessions ? (
          <div>
            {currentDaySessions.length} session
            {currentDaySessions.length > 0 ? "s" : ""} in selected date:
            {currentDaySessions.map((session) => {
              console.log(session);
              return (
                <Session
                  key={session._id}
                  // id={session._id}
                  // startTime={session.startTime}
                  // status={session.status}
                  // subject={session.subjectId}
                  // studentId={session.studentId}
                  sessionData={session}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CalendarView;
