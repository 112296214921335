import CalendarView from "../components/Calendar";

const Schedule = () => {
  return (
    <div>
      <h1>My Schedule</h1>
      <p>This is the Schedule page.</p>
      <CalendarView />
    </div>
  );
};

export default Schedule;
